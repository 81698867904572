<template>
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6875 5.802H1.4375M9.5625 0.802002V3.302M4.5625 0.802002V3.302M4.4375 13.302H9.6875C10.7376 13.302 11.2626 13.302 11.6637 13.0976C12.0165 12.9179 12.3034 12.631 12.4831 12.2782C12.6875 11.8771 12.6875 11.3521 12.6875 10.302V5.052C12.6875 4.0019 12.6875 3.47685 12.4831 3.07577C12.3034 2.72297 12.0165 2.43613 11.6637 2.25636C11.2626 2.052 10.7376 2.052 9.6875 2.052H4.4375C3.3874 2.052 2.86235 2.052 2.46127 2.25636C2.10846 2.43613 1.82163 2.72297 1.64186 3.07577C1.4375 3.47685 1.4375 4.0019 1.4375 5.052V10.302C1.4375 11.3521 1.4375 11.8771 1.64186 12.2782C1.82163 12.631 2.10846 12.9179 2.46127 13.0976C2.86235 13.302 3.3874 13.302 4.4375 13.302Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconCalendarSolid'
}
</script>
